import { toHeaderCase } from 'js-convert-case'

export const useBreadcrumbs = () => {
  type crumb = { name: string; path: string }[]

  const route = useRoute()
  const router = useRouter()

  const breadcrumbs = ref<crumb>([])

  const getBreadcrumbs = () => {
    const matches = <crumb>[{ name: 'Home', path: '/' }]

    let parentRoute

    if (route.meta.breadcrumb?.parent) {
      const parentName = route.path.split('/')[1]

      parentRoute = router.resolve({
        name: parentName,
      })

      matches.push({ name: toHeaderCase(parentName), path: parentRoute.path })
    }

    route.matched.forEach(match => {
      const name = (match.meta.breadcrumb?.name || match.name || match.path) as string

      if (!match.meta.breadcrumb?.disabled) {
        matches.push({ name: name, path: match.path })
      }
    })

    return matches
  }

  watch(
    () => ({
      path: route.path,
      name: route.name,
      meta: route.meta,
      matched: route.matched,
    }),
    _ => {
      breadcrumbs.value = getBreadcrumbs()
    },
    {
      immediate: true,
    }
  )

  return { breadcrumbs }
}
